var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["rankimgBox", _vm.rankingType == 2 ? "rankimgBox2" : ""] },
    [
      _c(
        "div",
        { staticClass: "rankingdate" },
        _vm._l(_vm.rankingdate, function (item, index) {
          return _c(
            "p",
            {
              key: index,
              class: { ranksel: _vm.typesel == index },
              on: {
                click: function ($event) {
                  return _vm.typeClick(item, index)
                },
              },
            },
            [_vm._v(_vm._s(item.label))]
          )
        }),
        0
      ),
      !_vm.isLoading
        ? _c(
            "ul",
            { staticClass: "rankinglist" },
            _vm._l(_vm.rankingData, function (item, index) {
              return _c("li", { key: index, staticClass: "d-flex a-center" }, [
                index == 0
                  ? _c("img", {
                      staticClass: "pm",
                      attrs: { src: require("../assets/imgs/ranking1.png") },
                    })
                  : _vm._e(),
                index == 1
                  ? _c("img", {
                      staticClass: "pm",
                      attrs: { src: require("../assets/imgs/ranking2.png") },
                    })
                  : _vm._e(),
                index == 2
                  ? _c("img", {
                      staticClass: "pm",
                      attrs: { src: require("../assets/imgs/ranking3.png") },
                    })
                  : _vm._e(),
                index != 0 && index != 1 && index != 2
                  ? _c("p", { staticClass: "rankNumber" }, [
                      _vm._v(_vm._s(index + 1)),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "avatarBox",
                    on: {
                      click: function ($event) {
                        return _vm.goUserHome(item)
                      },
                    },
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: item.imgUrl,
                          expression: "item.imgUrl",
                        },
                      ],
                      key: item.imgUrl,
                      staticClass: "rankingimg",
                    }),
                    index <= 2 && item.svgaUrlT1
                      ? _c("svgaCom", {
                          staticClass: "avatarSvga",
                          attrs: {
                            svgaUrl: item.svgaUrlT1,
                            id: `rank${_vm.rankingType}_${item.id}`,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "rankingInfo" }, [
                  _c("p", [_vm._v(_vm._s(item.nickName))]),
                  _c("span", [_vm._v("随心号:" + _vm._s(item.uid))]),
                  item.svgaUrlT6
                    ? _c("div", { staticClass: "tags d-flex" }, [
                        _c("img", {
                          staticClass: "svgaUrlT6",
                          attrs: { src: item.svgaUrlT6, alt: "" },
                        }),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "charm d-flex a-center j-end flex-1" },
                  [
                    _vm.rankingType == 1
                      ? _c("img", {
                          attrs: { src: require("../assets/imgs/ml1.png") },
                        })
                      : _vm._e(),
                    _vm.rankingType == 2
                      ? _c("img", {
                          attrs: { src: require("../assets/imgs/ml2.png") },
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(item.diamond))]),
                  ]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }