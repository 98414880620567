var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index", attrs: { id: "index", name: "index" } },
    [
      _c("div", { staticClass: "bodyl" }),
      _c("div", { staticClass: "bodyr" }),
      _c(
        "div",
        { staticClass: "banner" },
        [
          _c(
            "el-carousel",
            { attrs: { height: "400px" } },
            _vm._l(_vm.bannerList, function (item) {
              return _c("el-carousel-item", { key: item.id }, [
                _c("img", {
                  staticStyle: {
                    height: "400px",
                    display: "block",
                    margin: "auto",
                  },
                  attrs: { src: item.imgWeb },
                  on: {
                    click: function ($event) {
                      return _vm.bannerHandle(item)
                    },
                  },
                }),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c("CardList", {
            attrs: {
              titleImg: "1",
              title: "推荐房间",
              titleType: _vm.recommend1,
              cardData: _vm.suggestRoomList,
              isCardTit: _vm.isCardTit,
            },
          }),
          _c("CardList", {
            attrs: {
              titleImg: "2",
              title: "明星房间",
              titleType: _vm.recommend2,
              cardData: _vm.roomList,
              isCardTit: _vm.isCardTit,
            },
            on: { tabHandle: _vm.tabHandle },
          }),
          _c("CardList", {
            attrs: {
              titleImg: "3",
              title: "大神推荐",
              titleType: _vm.recommend3,
              cardData: _vm.suggestLiveList,
              isCardTit: _vm.isCardTit,
            },
            on: { tabHandle: _vm.tabHandle },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "rankingOf" },
        [
          _c("RankingList", {
            attrs: { rankingType: "1", rankingData: _vm.wealthList },
            on: { tabHandle: _vm.rankTabHandle },
          }),
          _c("RankingList", {
            attrs: { rankingType: "2", rankingData: _vm.charmList },
            on: { tabHandle: _vm.rankTabHandle },
          }),
        ],
        1
      ),
      _c("BarrageCom"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }