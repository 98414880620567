<template>
	<div :class="['rankimgBox', rankingType == 2 ? 'rankimgBox2' : '']">
		<div class="rankingdate">
			<p :class="{ ranksel: typesel == index }" v-for="(item, index) in rankingdate" :key="index"
				@click="typeClick(item, index)">{{ item.label }}</p>
		</div>
		<ul class="rankinglist" v-if="!isLoading">
			<li class="d-flex a-center" v-for="(item, index) in rankingData" :key="index">
				<img src="../assets/imgs/ranking1.png" class="pm" v-if="index == 0" />
				<img src="../assets/imgs/ranking2.png" class="pm" v-if="index == 1" />
				<img src="../assets/imgs/ranking3.png" class="pm" v-if="index == 2" />
				<p class="rankNumber" v-if="index != 0 && index != 1 && index != 2">{{ index + 1 }}</p>
				<div class="avatarBox" @click="goUserHome(item)">
					<img v-lazy="item.imgUrl" :key="item.imgUrl" class="rankingimg" />
					<svgaCom class="avatarSvga" v-if="index <= 2 && item.svgaUrlT1" :svgaUrl="item.svgaUrlT1"
						:id="`rank${rankingType}_${item.id}`" />
				</div>
				<div class="rankingInfo">
					<p>{{ item.nickName }}</p>
					<span>随心号:{{ item.uid }}</span>
					<div class="tags d-flex" v-if="item.svgaUrlT6">
						<img class="svgaUrlT6" :src="item.svgaUrlT6" alt="">
					</div>
				</div>
				<div class="charm d-flex a-center j-end flex-1">
					<img src="../assets/imgs/ml1.png" v-if="rankingType == 1" />
					<img src="../assets/imgs/ml2.png" v-if="rankingType == 2" />
					<span>{{ item.diamond }}</span>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import svgaCom from '@/components/svgaCom.vue'
export default {
	components: {
		svgaCom
	},
	props: {
		rankingData: {
			type: Array,
		},
		rankingType: {
			type: String,
		}
	},
	data() {
		return {
			isLoading: true,
			typesel: 0,
			rankingdate: [{
				value: 4,
				label: '日榜',
			}, {
				value: 3,
				label: '周榜',
			}, {
				value: 2,
				label: '月榜',
			}],
		}
	},
	watch: {
		rankingData() {
			// console.log(newVal)
			this.isLoading = false
		}
	},
	methods: {
		typeClick(item, index) {
			this.typesel = index
			console.log("item", item);
			this.isLoading = true
			this.$emit('tabHandle', {
				rankingType: this.rankingType,
				type: item.value
			})
		},

		// 查看大哥主页
		goUserHome(item) {
			this.$router.push(`/homePage/${item.uid}`)
		},
	}
}
</script>
<style lang="less">
.rankimgBox {
	width: 586px;
	height: 1384px;
	background: url(../assets/imgs/r1.png) no-repeat;

	&.rankimgBox2 {
		background: url(../assets/imgs/r2.png) no-repeat;

		.rankingdate {
			p {
				&.ranksel {
					background: rgba(181, 134, 242, 0.2);
					color: #B586F2;
				}
			}
		}
	}

	.rankingdate {
		width: 344px;
		height: 34px;
		background: rgba(255, 255, 255, 0.05);
		border-radius: 20px;
		padding: 3px;
		display: flex;
		justify-content: space-between;
		margin: 93px auto 32px;

		p {
			flex: 1;
			// width: 85px;
			height: 34px;
			line-height: 34px;
			text-align: center;
			font-size: 16px;
			color: #A69E8F;
			border-radius: 17px;
			cursor: pointer;

			&.ranksel {
				background: rgba(244, 233, 208, 0.2);
				color: #F6EBD4;
			}
		}
	}

	.rankinglist {
		li {
			height: 96px;
			margin-bottom: 24px;
			padding: 0 36px;

			.rankNumber {
				width: 32px;
				height: 32px;
				background: rgba(255, 255, 255, 0.1);
				border-radius: 16px;
				color: #fff;
				text-align: center;
				line-height: 32px;
				font-size: 14px;
				margin-right: 32px;
			}

			.pm {
				width: 40px;
				margin-right: 28px;
			}

			.avatarBox {
				position: relative;
				margin-right: 20px;
				cursor: pointer;

				.rankingimg {
					width: 80px;
					height: 80px;
					border-radius: 48px;
					object-fit: cover;
				}

				.avatarSvga {
					width: 110px;
					height: 110px;
					position: absolute;
					left: -15px;
					top: -15px;
				}
			}

			.rankingInfo {
				width: 180px;

				p {
					font-size: 16px;
					color: #fff;
					margin-right: 7px;
				}

				span {
					font-size: 14px;
					color: #9f9792;
				}

				.tags {
					margin-top: 10px;

					.svgaUrlT6 {
						display: block;
						height: 20px;
					}
				}
			}

			.charm {
				position: relative;

				img {
					width: 27px;
					margin-right: 4px;
				}

				span {
					font-size: 16px;
					color: #fff;
				}
			}
		}
	}
}
</style>
<style></style>
