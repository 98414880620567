var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "barrages-drop", class: { barragesBg: _vm.barrageIsShow } },
    [
      _c("vue-baberrage", {
        attrs: {
          barrageList: _vm.barrageList,
          boxHeight: _vm.boxHeight,
          isShow: _vm.barrageIsShow,
          lanesCount: _vm.lanesCount,
          loop: _vm.barrageLoop,
          messageHeight: _vm.messageHeight,
          throttleGap: _vm.throttleGap,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (slotProps) {
              return [
                _c("div", { staticClass: "normal" }, [
                  _c("div", { staticClass: "baberrage-avatar" }, [
                    _c("img", { attrs: { src: slotProps.item.giftIcon } }),
                  ]),
                  _c("div", { staticClass: "baberrage-msg" }, [
                    _c("span", [_vm._v(_vm._s(slotProps.item.liveNickname))]),
                    _vm._v("送给"),
                    _c("span", [
                      _vm._v(_vm._s(slotProps.item.targetLiveNickname)),
                    ]),
                    _vm._v(
                      _vm._s(slotProps.item.num) +
                        _vm._s(slotProps.item.giftName) +
                        " "
                    ),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm.barrageIsShow
        ? _c("div", {
            staticClass: "iconClose",
            on: { click: _vm.closeHandle },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }