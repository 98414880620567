<template>
	<div class="index" id="index" name="index">
		<!-- 左右发光图 -->
		<div class="bodyl"></div>
		<div class="bodyr"></div>
		<!-- 轮播图 -->
		<div class="banner">
			<el-carousel height="400px">
				<el-carousel-item v-for="(item) in bannerList" :key="item.id">
					<img style="height:400px;display: block;margin: auto;" :src="item.imgWeb" @click="bannerHandle(item)" />
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="mt20">
			<!-- 推荐房间 -->
			<CardList titleImg="1" title="推荐房间" :titleType="recommend1" :cardData="suggestRoomList"
				:isCardTit="isCardTit" />
			<!-- 明星房间 -->
			<CardList titleImg="2" title="明星房间" :titleType="recommend2" :cardData="roomList" @tabHandle="tabHandle"
				:isCardTit="isCardTit" />
			<!-- 大神推荐 -->
			<CardList titleImg="3" title="大神推荐" :titleType="recommend3" :cardData="suggestLiveList" @tabHandle="tabHandle"
				:isCardTit="isCardTit" />
		</div>
		<div class="rankingOf">
			<!-- 财富榜 -->
			<RankingList rankingType="1" :rankingData="wealthList" @tabHandle="rankTabHandle" />
			<!-- 魅力榜 -->
			<RankingList rankingType="2" :rankingData="charmList" @tabHandle="rankTabHandle" />
		</div>

		<!-- 弹幕 -->
		<BarrageCom />
	</div>
</template>

<script>
import CardList from '@/components/CardList.vue'
import RankingList from '@/components/RankingList.vue'
import BarrageCom from '@/components/BarrageCom.vue'
import {
	getADList,
	getSuggestLiveList,
	getClassSkillList,
	getSkillLiveList,
	getSpendList,
	getReceiveList,
	getSuggestRoomList,
	getRoomList,
	getRoomType,
	getRoomTypeList
} from "@/util/api/api.js"
export default {
	components: {
		CardList,
		RankingList,
		BarrageCom
	},
	data() {
		return {
			isCardTit: true,
			recommend1: [],
			recommend2: [{
				id: 'allRoom',
				value: 1,
				name: '全部',
			}],
			recommend3: [{
				id: 'suggest',
				value: 1,
				name: '推荐',
			}],
			suggestRoomList: [],// 推荐房间列表
			roomList: [],//明星房间列表
			bannerList: [],// banner列表
			suggestLiveList: [],// 大神推荐列表数据
			wealthList: [],// 财富榜列表
			wealthType: 4,// 财富榜分类type：//1 总榜 2月榜 3周榜 4日榜
			charmList: [],// 魅力榜列表
			charmType: 4,// 魅力榜分类type：//1 总榜 2月榜 3周榜 4日榜
		}

	},

	created() {
		this._getADList()
		this._getSuggestRoomList()
		this._getRoomList()
		this._getRoomType()
		this._getSuggestLiveList()
		this._getClassSkillList()
		this._getSpendList()
		this._getReceiveList()
	},
	methods: {
		// 获取banner列表数据
		async _getADList() {
			try {
				let res = await getADList()
				console.log(res)
				if (res.data && res.data.list.length > 0) {
					this.bannerList = res.data.list
				}
			} catch (error) {
				console.log(error)
			}
		},

		// banner点击事件
		bannerHandle(data) {
			console.log(data)
			window.open(data.adUrl, '_blank');
		},

		// 获取推荐房间列表
		async _getSuggestRoomList() {
			try {
				let params = {
					isShow: 1,
					pageNum: 1,
					pageSize: 5,
				}
				let res = await getSuggestRoomList(params)
				console.log(res)
				if (res.resultCode === '000000' && res.data && res.data.list.length >= 0) {
					this.suggestRoomList = res.data.list
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 获取全部房间列表
		async _getRoomList() {
			try {
				let params = {
					pageNum: 1,
					pageSize: 10,
					isShow: 1
				}
				let res = await getRoomList(params)
				console.log(res)
				if (res.resultCode === '000000' && res.data && res.data.list.length >= 0) {
					this.roomList = res.data.list
					this.roomList.length = this.roomList.length > 10 ? 10 : this.roomList.length
				}
			} catch (error) {
				console.log(error)
			}
		},
		// 获取房间分类列表
		async _getRoomType() {
			try {
				let res = await getRoomType()
				if (res.data && res.data.list.length >= 0) {
					this.recommend2 = [
						...this.recommend2,
						...res.data.list
					]
				}
			} catch (error) {
				console.log(error)
			}
		},
		// 获取房间分类对应的房间列表
		async _getRoomTypeList(id) {
			try {
				let params = {
					type: id,
					isShow: 1,
					pageNum: 1,
					pageSize: 10
				}
				let res = await getRoomTypeList(params)
				if (res.data && res.data.list.length >= 0) {
					this.roomList = res.data.list
					this.roomList.length = this.roomList.length > 10 ? 10 : this.roomList.length
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 获取大神推荐列表
		async _getSuggestLiveList() {
			try {
				let params = {
					pageNum: 1,
					pageSize: 5
				}
				let res = await getSuggestLiveList(params)
				console.log(res)
				if (res.data && res.data.list.length >= 0) {
					this.suggestLiveList = res.data.list
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 获取大神技能分类列表
		async _getClassSkillList() {
			try {
				let res = await getClassSkillList()
				if (res.data && res.data.list.length >= 0) {
					this.recommend3 = [
						...this.recommend3,
						...res.data.list
					]
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 获取大神技能分类对应的大神列表
		async _getSkillLiveList(id) {
			try {
				let params = {
					id: id,
					pageNum: 1,
					pageSize: 5
				}
				let res = await getSkillLiveList(params)
				if (res.data && res.data.list.length >= 0) {
					this.suggestLiveList = res.data.list
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 推荐tab切换事件
		tabHandle(data) {
			// console.log(data)
			let { titleImg, tab } = data
			// 大神推荐
			if (titleImg == 3) {
				if (tab.id == 'suggest') {
					this._getSuggestLiveList()
				} else {
					this._getSkillLiveList(tab.id)
				}
			} else if (titleImg == 2) {
				// 明星全部房间
				if (tab.id == 'allRoom') {
					this._getRoomList()
				} else {
					// 各分类房间
					this._getRoomTypeList(tab.id)
				}
			}
		},

		// 获取财富榜列表数据
		async _getSpendList() {
			try {
				let params = {
					pageNum: 1,
					pageSize: 10,
					type: this.wealthType,
					platform: 1
				}
				let res = await getSpendList(params)
				if (res.data && res.data.list.length >= 0) {
					this.wealthList = res.data.list
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 获取魅力榜列表数据
		async _getReceiveList() {
			try {
				let params = {
					pageNum: 1,
					pageSize: 10,
					type: this.charmType,
					platform: 1
				}
				let res = await getReceiveList(params)
				if (res.data && res.data.list.length >= 0) {
					this.charmList = res.data.list
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 财富榜\魅力榜tab切换事件
		rankTabHandle(data) {
			console.log(data)
			let { rankingType, type } = data
			// 财富榜
			if (rankingType == 1) {
				this.wealthType = type
				this._getSpendList()
			} else {
				// 魅力榜
				this.charmType = type
				this._getReceiveList()
			}
		}
	},

}
</script>

<style lang="less" scoped>
.index {
	// height: 2500px;
	padding: 0;
	position: relative;

	.bodyl {
		width: 419px;
		height: 858px;
		position: absolute;
		top: 400px;
		left: 0;
		background: url(../assets/imgs/bodyl.png) no-repeat;
	}

	.bodyr {
		width: 493px;
		height: 858px;
		position: absolute;
		top: 400px;
		right: 0;
		background: url(../assets/imgs/bodyr.png) no-repeat;
	}

	.rankingOf {
		width: 1200px;
		margin: 40px auto 64px;
		display: flex;
		justify-content: space-between;
	}
}
</style>
<style lang="less">
.banner .el-carousel__indicator--horizontal {
	padding: 20px 10px;
}

.banner .el-carousel__button {
	width: 8px;
	height: 8px;
	background: rgba(255, 255, 255, 0.8);
	border-radius: 8px;
}

.banner .is-active button {
	width: 24px;
	height: 8px;
	background: #FFFFFF;
	border-radius: 4px;
}

.banner .el-carousel__arrow--left,
.banner .el-carousel__arrow--right {
	width: 72px;
	height: 72px;
	background-color: rgba(32, 26, 37, 0.4);
}

.banner .el-icon-arrow-left,
.banner .el-icon-arrow-right {
	font-size: 40px;
	font-weight: bold;
}

.mt20 {
	margin-top: 36px;
}

.cardBox {
	min-height: 300px;
}
</style>
