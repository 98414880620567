<template>
	<div class="barrages-drop" :class="{ barragesBg: barrageIsShow }">
		<vue-baberrage :barrageList="barrageList" :boxHeight="boxHeight" :isShow="barrageIsShow" :lanesCount="lanesCount"
			:loop="barrageLoop" :messageHeight="messageHeight" :throttleGap="throttleGap">
			<template v-slot:default="slotProps">
				<div class="normal">
					<div class="baberrage-avatar"><img :src="slotProps.item.giftIcon"></div>
					<div class="baberrage-msg">
						<span>{{ slotProps.item.liveNickname }}</span>送给<span>{{ slotProps.item.targetLiveNickname
						}}</span>{{ slotProps.item.num }}{{ slotProps.item.giftName }}
					</div>
				</div>
			</template>
		</vue-baberrage>
		<div class="iconClose" v-if="barrageIsShow" @click="closeHandle"></div>
	</div>
</template>
<script>
import Vue from 'vue';
import {
	vueBaberrage,
	MESSAGE_TYPE
} from 'vue-baberrage';
import { getFlyList } from '../util/api/api';
Vue.use(vueBaberrage);
export default {
	name: 'Barrages',
	data() {
		return {
			barrageList: [],
			barrageIsShow: true, //是否展示弹幕
			barrageLoop: false, //是否循环播放
			boxHeight: 120, //高度
			messageHeight: 25, //消息高度
			lanesCount: 3, //泳道数量
			throttleGap: 2000, //消息间隔

		};
	},
	methods: {
		// 获取飘屏数据
		async _getFlyList() {
			try {
				let res = await getFlyList()
				if (res.data && res.data.list) {
					res.data.list.forEach((item) => {
						item.type = MESSAGE_TYPE.NORMAL //类型
						item.time = Math.round(Math.random() * 5 + 10)
					})
					this.barrageList = this.barrageList.concat(res.data.list)
				}
			} catch (error) {
				console.log(error)
			}
		},
		// 关闭弹窗
		closeHandle() {
			this.barrageIsShow = false
			clearInterval(this.timer)
			this.timer = null
		}
	},

	// 开启定时器
	activated() {
		this._getFlyList()
		this.timer = setInterval(() => {
			this._getFlyList()
		}, 10000);
	},

	// 关闭定时器
	deactivated() {//keep-alive的隐藏的钩子函数
		clearInterval(this.timer)
		this.timer = null
	},

	// beforeDestroy() {
	// 	clearInterval(this.timer)
	// }
};
</script>
<style scoped lang="less">
.barrages-drop {
	width: 100%;
	position: fixed;
	bottom: 0px;
	left: 0;
	z-index: 998;
	height: 140px;
	display: none;
}

.barragesBg {
	display: block;
	background: rgba(0, 0, 0, .2);
}

/deep/ .baberrage-stage {
	height: 120px !important;
	bottom: 8px !important;
	overflow: inherit !important;
}

/deep/ .baberrage-item .normal {
	padding: 0px 16px 0px 42px !important;
	height: 32px;
	line-height: 32px;
	background: #B586F2 !important;
	border-radius: 0px 16px 16px 0px;
	border: 1px solid #DDC2FF;
}

/deep/ .baberrage-item .normal .baberrage-avatar {
	width: 64px !important;
	height: 64px !important;
	position: absolute;
	top: -11px;
	left: -16px;
}

/deep/ .baberrage-item .normal .baberrage-avatar img {
	width: 64px !important;
	height: 64px !important;
}

/deep/ .baberrage-item .normal .baberrage-msg {
	font-size: 14px;
	color: #fff;
	line-height: 32px;
}

/deep/ .baberrage-item .normal .baberrage-msg span {
	color: #FFDD5A;
	margin: 0 8px;
}

.iconClose {
	height: 32px;
	width: 32px;
	background: url('../assets/imgs/icon_flyClose.png')no-repeat center/cover;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
}
</style>
